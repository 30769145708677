// https://colorhunt.co/palette/131842

/* Bring in your custom variables */
// @import '_variables.scss';

/* or just overwrite a variable */

// @import 'bootstrap-overrides.scss';
// @import 'bootstrap/bootstrap.scss';


$light: #fbf9fa;
$bg: $light;
$dark: #2b2024;
$base-text-color: $dark;
$hl: #fd0054;

$main-heading-color: lighten($dark, 20);

$animation-speed: 0.35s;
$box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.35);
$inner-box-shadow: inset $box-shadow;

body {
  text-align: center;
}

html { // per https://github.com/mui-org/material-ui/issues/6656
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

body, #root { // per https://github.com/mui-org/material-ui/issues/6656
  height: 100% !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

nav { // per https://github.com/mui-org/material-ui/issues/6656
  padding: 0 !important;
}

// h1 {
//   color: $main-heading-color;
//   margin: 20px 0 0 0;
// }

div.mugshot-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div.mugshot-frame {
    min-height: 250px;
    min-width: 250px;
    border-radius: 50%;
    border: 8px solid $light;
    box-shadow: $box-shadow, $inner-box-shadow;
    margin: 20px 0;
  }
}

@media screen and (max-width: 450px) {
  div.mugshot-container div.mugshot-frame {
    min-height: 200px;
    min-width: 200px;
  }
}

.skills-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  flex-wrap: wrap;
}

.badge {
  position: relative;
  height: 120px;
  flex: 0 0 2.5em;
  width: 2.5em;
  text-align: center;
  color: #bbb;
  display: inline-block;
  font-size: 3em;
  padding: 30px;
}

.badge-inner {
  transition: all $animation-speed;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// h1, h2 {
//   padding-top: 56px + 20px;
//   margin-top: 0;
// }

// h2 {
//   margin-top: -56px;
// }

// header {
//   padding-top: 56px;
// }

// .navbar {
//   position: fixed;
//   width: 100vw;
//   z-index: 1;
//   top: 0;
//   box-shadow: $box-shadow;
//   padding: 8px 116px; //originally: 8px 16px
//   width: calc(100% + 200px);
//   left: -100px;
// }

// section {
//   height: 1000px;
// }

// footer {
//   padding: 30px;
//   margin-top: 30px;
//   margin-left: -10px;
//   margin-right: -10px;
//   text-align: center;
//   font-size: 0.8em;
//   background: #cfd8dc;
// }

.sr-only {
  opacity: 0;
  display: inline-block;
  position: absolute;
}


.shinyButton {
  // https://codepen.io/dmensinger/pen/PPRzpK
  $speed: 3s;
  $opacity: 1;

  position: relative;
  // transition: .6s;
  overflow: hidden;
  &:before {
    content: '';
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 1 * $opacity);
    width: 60px;
    height: 100%;
    left: 0;
    top: 0;
    filter: blur(30px);
    animation: $speed infinite normal shine;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.4 * $opacity);
    width: 30px;
    height: 100%;
    left: 30px;
    top: 0;
    opacity: 0.6;
    filter: blur(5px);
    animation: $speed infinite normal shine;
  }
}

@keyframes shine {
  0% {
    transform: translateX(-100px) skewX(-15deg);
    opacity: 0;
  }
  30% {
    transform: translateX(-100px) skewX(-15deg);
  }
  70% {
    transform: translateX(300px)  skewX(-15deg);
  }
  100% {
        transform: translateX(300px)  skewX(-15deg);
  }
}


